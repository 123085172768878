.title {
  font-size: 50px;
  line-height: 52px;
}

.icon {
  max-width: 36px;
}

@media screen and (min-width: 1024px) {
  .icon {
    max-width: 50px;
  }
}

.btn {
  white-space: nowrap;
}
